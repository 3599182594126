import { actionTypes } from 'src/components/Chart/heatmapAction';
import { IheatmapState } from 'src/interface/IHeatmapState';

const initialState: IheatmapState = {
  data: [],
  dataTask: [],
  paginationToken: '',
  dataResource: [],
  isLoading: false,
  isLoadingTask: false,
  timeStart: '',
  timeEnd: ''
};

export function chartReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_PROJECT: {
      return {
        ...state,
        data: action.data
      };
    }
    case actionTypes.REQUEST_LIST_TASK: {
      return {
        ...state,
        isLoadingTask: true
      };
    }
    case actionTypes.GET_LIST_TASK: {
      if (action.reset) {
        return {
          ...state,
          dataTask: [...action.data.tasks],
          paginationToken: action.data.pagination_token,
          isLoadingTask: false
        };
      }

      return {
        ...state,
        dataTask: [...state.dataTask, ...action.data.tasks],
        paginationToken: action.data.pagination_token,
        isLoadingTask: false
      };
    }
    case actionTypes.RESET_LIST_TASK: {
      return {
        ...state,
        dataTask: [],
        paginationToken: '',
        isLoadingTask: false
      };
    }
    case actionTypes.REQUEST_DATA_HEATMAP: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_DATA_HEATMAP: {
      return {
        ...state,
        dataResource: action.data,
        isLoading: false
      };
    }
    case actionTypes.GET_DATE_CURRENT: {
      return {
        ...state,
        timeStart: action.data.from_date,
        timeEnd: action.data.to_date
      };
    }
    default:
      return state;
  }
}
