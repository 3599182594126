import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import API_ROUTES from 'src/common/apiRoutes';
import { appAxios } from './appAxios';

export const chartApi = {
  getListProject: async (): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.HEATMAP_PROJECTS);
      return get(data, 'data.projects', []);
    } catch (error) {
      // console.log(error);
    }
  },
  getListTask: async (params: {
    backlog_id: number;
    pagination_token?: string;
    search?: string;
    filter_by_status: string;
    filter_by_user: string;
    project_id: number;
  }): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.HEATMAP_TASKS, {
        params: {
          backlog_project_id: params.backlog_id,
          pagination_token: params.pagination_token,
          search: params.search,
          filter_by_status: params.filter_by_status,
          filter_by_user: params.filter_by_user,
          project_id: params.project_id
        }
      });
      return get(data, 'data', []);
    } catch (error) {
      // console.log(error);
    }
  },
  getDataHeatmap: async (params: {
    from_date: string;
    to_date: string;
  }): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.HEATMAP_RECORDS, { params });
      return get(data, 'data.records', []);
    } catch (error) {
      // console.log(error);
    }
  }
};
