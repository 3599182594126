import { IAppState } from 'src/interface/IAppState';
import { FormItemState } from 'src/interface/IFormState';
import { IinforProject } from 'src/interface/IHeatmapState';

export const formSelector = (appState: IAppState): FormItemState[] => appState.formState.form;

export const formIndexSelector = (appState: IAppState): number => appState.formState.formIndex;

export const dateSelector = (appState: IAppState): any => appState.formState.date;

export const projectSelector = (appState: IAppState): IinforProject[] => appState.heatmapState.data;
