import dayjs from 'dayjs';
import { FormState, FormItemState } from 'src/interface/IFormState';
import { actionTypes } from './formAction';

const initFormState: FormState = {
  form: [
    {
      id: 'default',
      isPassed: false,
      isEdit: false,
      isVisible: true,
      project: '',
      activity: '',
      hour: null,
      order: 999,
      isCopy: false,
      taskId: 0,
      backlogKey: '',
      taskName: ''
    }
  ],
  formIndex: 1,
  date: dayjs(),
  lastedPost: null
};

const initValue = () => {
  return {
    id: 'default',
    isPassed: false,
    isVisible: true,
    isEdit: false,
    project: undefined,
    taskId: 0,
    backlogKey: '',
    taskName: '',
    activity: '',
    hour: null,
    order: 999
  };
};

const formReducer = (state: FormState = initFormState, action: any) => {
  switch (action.type) {
    case actionTypes.HEATMAP_ADD: {
      const old = state.form.map((item) => {
        if (item.id === 'default') {
          return initValue();
        }
        return item;
      });
      return {
        ...state,
        form: [...old, action.data]
      };
    }
    case actionTypes.SET_FORM_INDEX:
      return {
        ...state,
        formIndex: action.idx
      };
    case actionTypes.HEATMAP_EDIT_FORM: {
      const { index } = action;
      return {
        ...state,
        form: state.form.map((item: FormItemState) => {
          if (item.id !== index) return item;
          return {
            ...item,
            isVisible: !item.isVisible,
            isEdit: !item.isEdit
          };
        })
      };
    }

    case actionTypes.HEATMAP_CHANGE: {
      const { data } = action;
      return {
        ...state,
        form: state.form.map((item) => {
          if (item.id === data.id) return data;
          return item;
        })
      };
    }
    case actionTypes.DATE_CHANGE: {
      return {
        ...state,
        date: action.data
      };
    }
    case actionTypes.REMOVE_HEATMAP_SUCCESS: {
      return {
        ...state,
        form: action.data
      };
    }
    case actionTypes.RESET_FORM: {
      const reset = state.form.map((item) => {
        if (item.id === 'default') {
          return initValue();
        }
        return item;
      });
      return {
        ...state,
        date: dayjs(),
        form: [...reset]
      };
    }
    case actionTypes.COPY_HEATMAP_SUCCESS: {
      return {
        ...state,
        form: action.data
      };
    }
    case actionTypes.POST_HEATMAP_SUCCESS: {
      return {
        ...state,
        lastedPost: new Date()
      };
    }
    case actionTypes.EDIT_HISTORY_HEATMAP: {
      const { data } = action;
      return {
        ...state,
        form: state.form.map((item: FormItemState) => {
          if (item.id !== data.id) return item;
          return {
            ...item,
            isVisible: !item.isVisible,
            activity: item.activity,
            hours: item.hours,
            date: item.date
          };
        })
      };
    }
    default:
      return state;
  }
};

export default formReducer;
